import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Features = () => {
  return (
    <>
      {/* <!-- Features Area --> */}
      <div className="pt-16  dark-mode-texts">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="7" xl="6">
              <div className="section-title text-center mb-13 mb-lg-0">
                <h2 className="title gr-text-3">
                  Lokacion i përshtatshëm me infrastrukturë moderne
                </h2>
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    </>
  );
};

export default Features;
