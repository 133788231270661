import React from "react";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/landing8/Hero";
import Content1 from "../sections/landing8/Content1";
import Process from "../sections/landing8/Process";
import Video from "../sections/landing8/Video";
import Features from "../sections/landing8/Features";
import Testimonials from "../sections/landing8/Testimonials";
import MapGoogle from "../components/MapGoogle";

const IndexPage = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "dark",
          align: "right",
          variant: "danger",
          isFluid: true,
          button: "null", // cta, account, null
        }}
        footerConfig={{
          style: "style1", //style1, style2
        }}
      >
        <Hero />
        <Content1 />
        <Process />
        <div
        className="hero-area-03 pt-29 pt-lg-32 pb-5 position-relative "
        css={`
          background-image: linear-gradient(
            225deg,
            #0BB89B 0%,
            #0BB89B 39%,
            #50BFD8 100%
          );
        `}
      >
          <Video />
          <Features />
          <Testimonials />
        </div>
        <div
          className="google-map fluid-map-height position-relative overflow-hidden"
          id="googleMap"
          css={`
            position: relative;
            overflow: hidden;
          `}
        >
          <div
            css={`
              height: 100%;
              width: 100%;
              position: absolute;
              top: 0px;
              left: 0px;
              background-color: rgb(11, 184, 155);
            `}
          >
            <MapGoogle />
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default IndexPage;