import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import imgT1 from "../../assets/image/l6/png/endrit.png";
import imgT2 from "../../assets/image/l6/jpg/veton.jpg";
import imgT3 from "../../assets/image/l6/jpg/djellza.jpg";

const Testimonials = () => {
  return (
    <>
      {/* <!-- Testimonials Area --> */}
      <div className="testimonial-section pt-15 pt-lg-22 pb-15 pb-lg-25 ">
        <Container>
          <Row className="justify-content-center dark-mode-texts">
            <Col md="8" lg="7" xl="6">
              <div className="section-title text-center mb-11 mb-lg-15">
                <h2 className="title-sm gr-text-5 mb-0 text-white">
                  <span className="strike-bottom green">
                    Mbi 1300 klientë 
                  </span>{" "}
                  ju besojnë shërbimeve tona rregullisht!
                </h2>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col
              xs="10"
              lg="8"
              className="mb-7"
              data-aos="fade-right"
              data-aos-duration="800"
            >
              <div className="testimonial-card d-flex flex-column flex-md-row align-items-md-center border rounded-12 bg-white pt-9 pb-8 px-9 gr-hover-opacity-full">
                <div className="card-image rounded-circle mr-9 mb-7 mb-md-0">
                  <img className="circle-xxl w-100" src={imgT1} alt="" />
                </div>
                <div className="testimonial-content">
                  <p className="review-text gr-text-7 text-blackish-blue mb-6">
                    “Sinqerisht mund të them se ata  kanë një shërbim të shkëlqyeshëm - Jo vetëm që ishin zgjidhja ime e parë për ilaçet e mia, por ata gjithashtu morën kohë për të më dhënë këshilla dhe ishin gjithmonë të lumtur të ndihmonin për çdo pyetje që kisha.”
                  </p>
                  <span className="name gr-text-9 text-blackish-blue gr-opacity-7 mb-0">
                    Endrit Hyseni
                  </span>
                </div>
              </div>
            </Col>
            <Col
              xs="10"
              lg="8"
              className="mb-7"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="200"
            >
              <div className="testimonial-card d-flex flex-column flex-md-row align-items-md-center border rounded-12 bg-white pt-9 pb-8 px-9 gr-opacity-7 gr-hover-opacity-full">
                <div className="card-image rounded-circle mr-9 mb-7 mb-md-0">
                  <img className="circle-xxl w-100" src={imgT2} alt="" />
                </div>
                <div className="testimonial-content">
                  <p className="review-text gr-text-7 text-blackish-blue mb-6">
                    “Në këtë pjesë afër Gjykatës themelore nuk ka pasë asnjë barnatore. Tash për çdo sen narapharma është gati n'derë tbanesës!”
                  </p>
                  <span className="name gr-text-9 text-blackish-blue gr-opacity-7 mb-0">
                    Veton Shabani
                  </span>
                </div>
              </div>
            </Col>
            <Col
              xs="10"
              lg="8"
              className="mb-7"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="400"
            >
              <div className="testimonial-card d-flex flex-column flex-md-row align-items-md-center border rounded-12 bg-white pt-9 pb-8 px-9 gr-opacity-5 gr-hover-opacity-full">
                <div className="card-image rounded-circle mr-9 mb-7 mb-md-0">
                  <img className="circle-xxl w-100" src={imgT3} alt="" />
                </div>
                <div className="testimonial-content">
                  <p className="review-text gr-text-7 text-blackish-blue mb-6">
                    “Farmacia Narapharma më ka ofruar një shërbim shumë të përshtatshëm të recetave nga mjeku im privat dhe kanë qenë gjithmonë plotësisht profesional në këshillat e përdorimit të tyre.”
                  </p>
                  <span className="name gr-text-9 text-blackish-blue gr-opacity-7 mb-0">
                    Djellza Isufi
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Testimonials;
